<template>
  <div class="anchor-link-content" ref="anchorLinkContent">
    <ul v-if="headings.length">
     <anchor-link-item-component
          v-for="item in headings"
          :key="item.id"
          :anchor-link="item"
        />
    </ul>
  </div>
</template>

<script>
import AnchorLinkItemComponent from './AnchorLinkItemComponent'
export default {
  name: 'AnchorLinkComponent',
  components: {
    AnchorLinkItemComponent
  },
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      anchorLinks: [],
      headings: []
    }
  },
  mounted () {
    this.parseHeadings()
    this.adjustAnchorLinkContent()
    const events = ['resize', 'scroll']
    events.forEach(event => {
      window.addEventListener(event, this.adjustAnchorLinkContent)
    })
  },
  beforeDestroy () {
    const links = this.$el.querySelectorAll('a[href^="#"]')
    links.forEach(link => {
      link.removeEventListener('click', this.scrollToAnchor)
    })
    const events = ['resize', 'scroll']
    events.forEach(event => {
      window.removeEventListener(event, this.adjustAnchorLinkContent)
    })
  },
  methods: {
    parseHeadings () {
      const maxHeadingLevel = parseInt(this.heading.replace('H', ''))
      const allHeadings = document.querySelectorAll(
        Array.from({ length: maxHeadingLevel }, (_, i) => `main article h${i + 1}`).join(', ')
      )
      const hierarchy = []
      const path = []
      let headingCounter = 1
      allHeadings.forEach(heading => {
        const level = parseInt(heading.tagName.replace('H', ''))
        const headingId = `heading-${headingCounter}`
        heading.id = headingId
        const headingObject = {
          id: heading.id,
          level,
          text: heading.textContent,
          children: []
        }
        headingCounter++
        while (path.length > 0 && level <= path[path.length - 1].level) {
          path.pop()
        }

        if (path.length > 0) {
          const parent = path[path.length - 1]
          parent.children.push(headingObject)
        } else {
          hierarchy.push(headingObject)
        }

        path.push(headingObject)
      })

      this.headings = hierarchy
    },
    adjustAnchorLinkContent () {
      const anchorLinkContent = this.$refs.anchorLinkContent
      if (!anchorLinkContent) return

      const isDesktop = this.$root.isDesktop
      const anchorLinkContentTop = anchorLinkContent.offsetTop + 118
      const scrollPosition = window.scrollY

      if (isDesktop && scrollPosition > anchorLinkContentTop) {
        const parentElement = anchorLinkContent.parentElement
        let offsetLeftParents = 0
        let currentElement = parentElement
        while (currentElement) {
          if (currentElement.classList.contains('position-relative')) {
            offsetLeftParents += currentElement.offsetLeft
          }
          currentElement = currentElement.parentElement
        }
        const offsetLeft = offsetLeftParents + parentElement.offsetWidth
        const width = parentElement.clientWidth
        anchorLinkContent.classList.add('affix')
        anchorLinkContent.style.left = `${offsetLeft}px`
        anchorLinkContent.style.width = `${(window.innerWidth - width) / 2}px`
        anchorLinkContent.style.maxWidth = 'unset'
      } else {
        anchorLinkContent.classList.remove('affix')
        anchorLinkContent.style.left = ''
        anchorLinkContent.style.maxWidth = ''
        anchorLinkContent.style.width = ''
      }
    }
  }
}
</script>
<style lang="scss">

.anchor-link-content {
  &.affix{
    position:fixed;
    left:unset;
    top:118px;
  }
  position:absolute;
  top:0;
  left:100%;
  max-width: calc((100vw - 100%) / 2);
  width: 100%;
  padding: 32px 10px 0 10px;

  ul{
    list-style-type: none;
    padding-left:10px;
    &:first-child{
      border-left: 4px solid $primary;
    }
    ul{
      margin-left: 20px;
      padding-left:0;
    }

    li{
      a{
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1200px) {
    position: static;
    left: auto;
    right: 10px;
    max-width: 100%;
    padding: 0;
  }

  @media (max-width: 768px) {
    position: static;
    max-width: 100%;
    padding: 0;
  }
}
</style>
