<template>
  <li>
    <a :href="`#${anchorLink.id}`">{{ anchorLink.text }}</a>
    <ul v-if="anchorLink.children.length">
      <anchor-link-item-component
        v-for="child in anchorLink.children"
        :key="child.id"
        :anchor-link="child"
      />
    </ul>
  </li>
</template>

<script>
import AnchorLinkItemComponent from './AnchorLinkItemComponent'
export default {
  name: 'AnchorLinkItemComponent',
  components: {
    AnchorLinkItemComponent
  },
  props: {
    anchorLink: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      const links = this.$el.querySelectorAll('a[href^="#"]')
      links.forEach(link => {
        link.addEventListener('click', this.scrollToAnchor)
      })
    })
  },
  methods: {
    scrollToAnchor (e) {
      e.preventDefault()

      const destId = e.target.getAttribute('href')
      const destination = document.querySelector(destId)

      if (destination) {
        const offsetTop = destination.offsetTop + 32

        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
